import "./logo-slider.scss";

/* Logo Slider -------------------------------------------------------------------------------------------------------*/

document.addEventListener("DOMContentLoaded", function () {
  // Select all instances of the logo slider on the page
  const logoSliders = document.querySelectorAll(".logo-slider");

  // Loop through each logo slider found
  logoSliders.forEach((slider, index) => {
    // Select all figure elements inside the logo slider
    const logoItems = gsap.utils.toArray(slider.querySelectorAll("figure"));

    // Get the direction from the data attribute
    const direction = slider.getAttribute('data-direction');

    // Dynamically set the width of each logo based on screen size and number of logos
    adjustLogoWidths(slider, logoItems);

    // Create the seamless loop using GSAP's horizontalLoop helper function
    const loop = horizontalLoop(logoItems, {
      speed: 0.5, // Control the speed of the animation (adjust as necessary)
      repeat: -1, // Infinite loop (repeat indefinitely)
      reversed: direction === 'right', // Reverse direction if the data-direction is 'right'
    });

    // Start the animation loop
    if (direction === 'right') {
      loop.reverse(0); // Reverse the timeline immediately from the start
    } else {
      loop.play(); // Play the animation normally
    }

    // Adjust logo widths on window resize
    window.addEventListener('resize', () => adjustLogoWidths(slider, logoItems));
  });

  /**
   * Function to adjust the width of each logo dynamically based on screen size and number of logos.
   * @param {Element} slider - The container element for the logo slider
   * @param {Array} logoItems - The array of figure elements (each containing a logo)
   */
  function adjustLogoWidths(slider, logoItems) {
    const totalLogos = logoItems.length;
    const screenWidth = window.innerWidth;
    let logosPerRow;

    // Set the number of logos per row based on screen size (responsive behavior)
    if (screenWidth >= 1500) {
      logosPerRow = 6; // For large screens (e.g., desktops)
    } else if (screenWidth >= 1200) {
      logosPerRow = 5; // For medium screens (e.g., tablets)
    } else if (screenWidth >= 768) {
      logosPerRow = 4; // For medium screens (e.g., tablets)
    } else {
      logosPerRow = 3; // For small screens (e.g., phones)
    }

    // If there are fewer logos than logosPerRow, adjust to fit all logos
    if (totalLogos < logosPerRow) {
      logosPerRow = totalLogos;
    }

    // Calculate the width for each logo item
    const logoWidth = 100 / logosPerRow + '%';

    // Apply the calculated width to each logo item
    logoItems.forEach(logo => {
      logo.style.width = logoWidth;
    });
  }

  /**
   * Helper function to create a horizontal, seamless loop for the items.
   * It uses GSAP to handle the continuous animation of elements, such as the logos.
   * @param {Array} items - Array of elements (e.g., the figures containing logos)
   * @param {Object} config - Configuration for the loop (speed, repeat, etc.)
   */
  function horizontalLoop(items, config) {
    items = gsap.utils.toArray(items); // Ensure the items are an array
    config = config || {}; // Use the provided config or default to an empty object

    // Create a GSAP timeline to handle the animation
    let tl = gsap.timeline({
          repeat: config.repeat, // Control the repeat behavior (infinite in this case)
          paused: config.paused, // Determine if the animation should start paused
          defaults: { ease: "none" }, // Set default easing to 'none' for a linear, continuous animation
          onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100), // Ensure smooth reverse behavior if reversed
        }),
        length = items.length, // Number of items to animate
        startX = items[0].offsetLeft, // Get the starting x position of the first item
        times = [], // Array to store when each item hits the starting point
        widths = [], // Array to store the width of each item
        xPercents = [], // Array to store the x percentage positions of each item
        curIndex = 0, // Track the current index of the item being animated
        pixelsPerSecond = (config.speed || 1) * 100, // Control the speed of the animation in pixels per second
        snap = config.snap === false ? (v) => v : gsap.utils.snap(config.snap || 1), // Snap positions to prevent uneven transitions due to flex layouts
        totalWidth, curX, distanceToStart, distanceToLoop, item, i;

    // Set the initial xPercent and width values for each item
    gsap.set(items, {
      xPercent: (i, el) => {
        let w = (widths[i] = parseFloat(gsap.getProperty(el, "width", "px")));
        xPercents[i] = snap((parseFloat(gsap.getProperty(el, "x", "px")) / w) * 100 + gsap.getProperty(el, "xPercent"));
        return xPercents[i];
      },
    });

    // Set x to 0 for all items to reset their position
    gsap.set(items, { x: 0 });

    // Calculate the total width of the loop (the sum of all items)
    totalWidth = items[length - 1].offsetLeft + (xPercents[length - 1] / 100) * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0);

    // Loop through each item and set up its animation in the timeline
    for (i = 0; i < length; i++) {
      item = items[i]; // Get the current item
      curX = (xPercents[i] / 100) * widths[i]; // Calculate the current x position
      distanceToStart = item.offsetLeft + curX - startX; // Calculate the distance from the starting position
      distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX"); // Calculate the distance needed to complete the loop

      // Add the animation to move the item to the left (or right if reversed)
      tl.to(
          item,
          {
            xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100), // Snap the position for smoothness
            duration: distanceToLoop / pixelsPerSecond, // Control the duration of the animation based on speed
          },
          0, // Start at the beginning of the timeline
      )
          .fromTo(
              item,
              {
                xPercent: snap(((curX - distanceToLoop + totalWidth) / widths[i]) * 100), // Move item back to start
              },
              {
                xPercent: xPercents[i], // Move to the calculated x position
                duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, // Duration based on the width and speed
                immediateRender: false, // Ensure it doesn't render immediately
              },
              distanceToLoop / pixelsPerSecond, // Offset the timing to allow looping
          )
          .add("label" + i, distanceToStart / pixelsPerSecond); // Add a label for each item for reference
      times[i] = distanceToStart / pixelsPerSecond; // Store the time for each item
    }

    return tl; // Return the timeline object for further use
  }
});